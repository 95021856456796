import { Stack } from '@mantine/core';
import PledgeSection from './pledge-section.component';
import Pledge from '@/models/treasury/pledges/Pledge';
import OrderHistoryWithName from '@/models/treasury/orderHistory/OrderHistoryWithName';
import OrderHistoryForm from '../order-history-form/order-history-form.component';
import EmailHistoryForm from '../email-history-form/email-history-form.component';
import EmailHistoryItem from '@/models/treasury/emailHistory/EmailHistoryItem';

interface PledgeCPFormProps {
    selectedEmail: string;
    pledges: Pledge[];
    orderHistories : OrderHistoryWithName[]
    deletePledge : (id: number)=>void;
    editPledge : (pledge: Pledge)=>void;
    emailHistory: EmailHistoryItem[],
    onClickAddEmail : ()=>void;
}

export default function PledgeCPForm({ selectedEmail, pledges, deletePledge, editPledge, ...restProps }: PledgeCPFormProps) {
    return (
        <Stack>
            <PledgeSection editPledge={editPledge} selectedEmail={selectedEmail} pledges={pledges} deletePledge={deletePledge}/>
            <OrderHistoryForm orders={restProps.orderHistories}/>
            <EmailHistoryForm emails={restProps.emailHistory} onClickAddEmail={restProps.onClickAddEmail}/>
        </Stack>
    );
}
