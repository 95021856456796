import Nullable from '@/dataTypes/Nullable';
import RewardsList from '@/models/treasury/rewards/RewardList';
import ITreasuryService from '@/services/ITreasuryService';
import { Button, Checkbox, Modal, NumberInput, Select, Stack, TextInput, LoadingOverlay } from '@mantine/core';
import { create } from 'domain';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';

export interface PledgeAddFormProps {
    visible: boolean;
    closed: () => void;
    email: string;
    create: (rewardId: number, backerKitEmail: string, quantity: number, imported : boolean) => void;
}

export default function PledgeAddForm({ visible, closed, email, create }: PledgeAddFormProps) {
    const [selectData, setSelectData] = useState<{ label: string; value: string }[]>([]);
    const [selectValue, setSelectValue] = useState<Nullable<string>>(null);
   
    const [loading, setLoading] = useState(false);
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');

    useEffect(() => {
        if (!!visible) {
            setLoading(true);

            treasuryService.getAllRewards().then((x) => {
                const tempSelectData = x
                    ?.getItems()
                    .map((it) => ({ label: it.reward_name, value: it.reward_id.toString() }));
                setSelectData(tempSelectData ?? []);
                setLoading(false);
            });
        }
    }, [visible]);

    useEffect(() => {
        setBackerKitEmail(email);
    }, [email]);

    const [backerKitEmail, setBackerKitEmail] = useState(email);
    const [customEmail, setCustomEmail] = useState(false);
    const [quantity, setQuantity] = useState(1); 
    const [imported, setImported] = useState(true)

    useEffect(() => {
        if (!customEmail) {
            setBackerKitEmail(email);
        }
    }, [customEmail]);

    const createHandler = () => {
        if (!selectValue) {
            closed();
            return;
        }
        const rewardValue = parseInt(selectValue);
        if (Number.isNaN(rewardValue)) {
            closed();
            return;
        }
        create(rewardValue, backerKitEmail, quantity, imported);
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Add Pledge">
            <Stack>
                <Select
                    searchable
                    data={selectData}
                    label="Reward"
                    value={selectValue}
                    onChange={(e) => {
                        setSelectValue(e);
                    }}
                />
                <Checkbox
                    checked={customEmail}
                    onChange={(e) => {
                        setCustomEmail(e.target.checked);
                    }}
                    label="Custom BackerKit Email"
                />
                <TextInput
                    label="BackerKit Email"
                    value={backerKitEmail}
                    disabled={!customEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerKitEmail(e.target.value);
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                <Checkbox
                    checked={imported}
                    onChange={(e) => {
                        setImported(e.target.checked);
                    }}
                    label="Imported"
                />
                <Button onClick={createHandler}>Add Pledge</Button>
            </Stack>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}
