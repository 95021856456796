import Pledge from '@/models/treasury/pledges/Pledge';
import { Button, Checkbox, Modal, NumberInput, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';

export interface PledgeEditFormProps {
    visible: boolean;
    closed: () => void;
    pledge: Pledge;
    edit: (
        pledge: Pledge,
        backerKitEmail: string,
        claimedQuantity: number,
        quantity: number,
        imported: boolean
    ) => void;
}

export default function PledgeEditForm({ visible, closed, pledge, edit }: PledgeEditFormProps) {
    useEffect(() => {
        if (!!pledge) {
            setBackerKitEmail(pledge.backerkit_email);
            setQuantity(pledge.quantity);
            setClaimedQuantity(pledge.claimed_quantity);
            setImported(pledge.imported);
        }
    }, [pledge]);

    const [backerKitEmail, setBackerKitEmail] = useState(pledge?.backerkit_email ?? '');
    const [quantity, setQuantity] = useState(pledge?.quantity ?? 0);
    const [claimedQuantity, setClaimedQuantity] = useState(pledge?.claimed_quantity ?? 0);
    const [imported, setImported] = useState(pledge?.imported ?? false);

    const editHandler = () => {
        edit(pledge, backerKitEmail, claimedQuantity, quantity, imported);
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Edit Pledge">
            <Stack>
                <TextInput label="Reward" value={pledge?.reward?.reward_name ?? 'Not Available'} readOnly disabled />
                <TextInput
                    label="BackerKit Email"
                    value={backerKitEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerKitEmail(e.target.value);
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                <NumberInput
                    label="Claimed Quantity"
                    value={claimedQuantity}
                    onChange={(e) => {
                        if (!!e) setClaimedQuantity(e);
                    }}
                />
                <Checkbox
                    checked={imported}
                    onChange={(e) => {
                        setImported(e.target.checked);
                    }}
                    label="Imported"
                />
                <Button onClick={editHandler}>Save Pledge</Button>
            </Stack>
        </Modal>
    );
}
