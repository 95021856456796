import Nullable from "@/dataTypes/Nullable";
import { Reward } from "@/models/treasury/rewards/Reward";
import RewardsList from "@/models/treasury/rewards/RewardList";
import ITreasuryService from "../ITreasuryService";
import type ITreasuryRESTClient from "@/rest-clients/ITreasuryRESTClient";
import { inject, injectable } from "inversify";
import CustomerList from "@/models/treasury/pledges/CustomerList";
import Pledge from "@/models/treasury/pledges/Pledge";
import OrderHistoryWithName from "@/models/treasury/orderHistory/OrderHistoryWithName";
import ShopifyDiagnosticsItem from "@/models/treasury/ShopifyDiagnosticsItem";
import EmailHistoryItem from "@/models/treasury/emailHistory/EmailHistoryItem";

@injectable()
export default class TreasuryService implements ITreasuryService{
   
    @inject('ITreasuryRESTClient')
    private treasuryRESTClient! : ITreasuryRESTClient;

    getRewards(page: number, search : string): Promise<Nullable<RewardsList>> {
        return this.treasuryRESTClient.getRewards(page, search);
    }
    getAllRewards(): Promise<Nullable<RewardsList>> {
        return this.treasuryRESTClient.getAllRewards()
    }
    getReward(id: number): Promise<Nullable<Reward>> {
        return this.treasuryRESTClient.getReward(id);
    }
    createReward(reward: Reward): Promise<boolean> {
        return this.treasuryRESTClient.createReward(reward);
    }
    updateReward(reward: Reward): Promise<boolean> {
        return this.treasuryRESTClient.updateReward(reward);
    }
    deleteReward(id: number): Promise<boolean> {
        return this.treasuryRESTClient.deleteReward(id);
    }

    uploadRewardImage(id: number, image: File) : Promise<Nullable<string>>{
        return this.treasuryRESTClient.uploadRewardImage(id, image);
    }
    deleteRewardImage(id : number) : Promise<boolean>{
        return this.treasuryRESTClient.deleteRewardImage(id);
    }


    getCustomers(page: number, search: string): Promise<Nullable<CustomerList>> {
        return this.treasuryRESTClient.getCustomers(page, search);
    }

    getCustomerPledges(email : string) : Promise<Pledge[]>{
        return this.treasuryRESTClient.getCustomerPledges(email);
    }
    getOrderHistory(iss: string): Promise<OrderHistoryWithName[]> {
        return this.treasuryRESTClient.getOrderHistory(iss);
    }

    createPledge(pledge: Pledge) : Promise<boolean>{
        return this.treasuryRESTClient.createPledge(pledge);
    }

    deletePledge(id : number) : Promise<boolean>{
        return this.treasuryRESTClient.deletePledge(id);
    }
    savePledge(pledge: Pledge) : Promise<boolean>{
        return this.treasuryRESTClient.savePledge(pledge);
    }

    updateEmail(oldEmail: string, newEmail: string): Promise<boolean> {
        return this.treasuryRESTClient.updateEmail(oldEmail, newEmail); 
    }

    getEmailHistory(email: string): Promise<EmailHistoryItem[]> {
        return this.treasuryRESTClient.getEmailHistory(email);
    }

    diagnose() : Promise<ShopifyDiagnosticsItem[]>{
        return this.treasuryRESTClient.diagnose();
    }
}