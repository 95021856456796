import { Button,  Modal, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';

export interface PledgeAddEmailProps {
    visible: boolean;
    closed: () => void;
    email: string;
    create: (ogEmail : string, targetEmail : string) => void;
}

export default function PledgeAddEmail({ visible, closed, email, create }: PledgeAddEmailProps) {
    const [ogEmail, setOgEmail] = useState('');
   
    useEffect(()=>{
        setOgEmail('')
    }, [visible])

    const createHandler = () => {
        if(!validateEmail(ogEmail)) {
            return;
        }

        if(ogEmail.trim() === '') {
            closed();
            return;
        }

        create(ogEmail, email);
    };

    const [error, setError] = useState('');

    const validateEmail = (email : string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Add Email">
            <Stack>
                <TextInput
                    type='email'
                    label="Original Email"
                    value={ogEmail}
                    error={error}
                    onChange={(e) => {
                        e.preventDefault();
                        const email = e.target.value;
                        setOgEmail(email);
                        if (!validateEmail(email)) {
                            setError('Invalid email address');
                        } else {
                            setError('');
                            // Handle valid email submission
                        }
                    }}/>
                <TextInput
                    label="Target Email"
                    value={email}
                    disabled
                />
               
                <Button onClick={createHandler}>Add Email</Button>
            </Stack>
        </Modal>
    );
}
